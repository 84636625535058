import axios from 'axios';
import qs from 'qs';
import Config from '../../../config/config';

const AuthService = {
  refreshToken: async (token: any) => {
    const data = qs.stringify({
      token,
    });

    const config = {
      method: 'put',
      url: `${Config.BACKEND_BASE_URL}api/sonet/heartbeat`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data,
    } as any;

    try {
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response) {
        console.error("Hearbeat HTTP error status:", error.response.status);
        throw new Error(`${error.response.status} - Forbidden`)
      } else {
        throw new Error(error);
      }
    }
  },
};

export default AuthService;
